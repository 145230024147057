import React, { useState, useEffect } from "react";

import {
  Card,
  Typography,
  CardHeader,
  Avatar,
  Button,
  Grid,
  Box,
  Hidden,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  LinearProgress,
  CircularProgress,
} from '@material-ui/core';

import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { blue, green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Prizes from "../prizes";
import LeadClientCreate from "../dialog/workflow-forms/lead_client-create";
import api from "../../services/api";
import { formatDateTime } from "../../services/date";
import { translateWorkflowStep } from "../../../workflow-config";
import TemperatureLabel from "../temperature-label";
import Statements from "../dialog/statements/statements";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    minHeight: 300,
  },
  actionRefers: {
    color: '#868EAE',
    fontSize: 11,
  },
  actionRefersActive: {
    color: '#5F63F2',
    fontSize: 11,
  },
  refersList: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  referAvatar: {
    backgroundColor: blue[500],
  },
}));

export default function ReferrerUser({
  apiReferenced = 'referenced',
  apiResume = 'referenced_resume',
  disablePoints = false,
  disableNew = false,
  apiStatements = '/me/statements',
  amountField="amount_user",
}) {
  const classes = useStyles();
  const [board, setBoard] = useState('doing');
  const [openLeadCreate, setOpenLeadCreate] = useState(false);
  const [openStatements, setOpenStatements] = useState(false);
  const [referencedLoaded, setReferencedLoaded] = useState(false);
  const [loadingReferrer, setLoadingReferrer] = useState(false);
  const [referenced, setReferenced] = useState([]);

  const [referencedLoadedResume, setReferencedLoadedResume] = useState(false);
  const [referencedResume, setReferencedResume] = useState([]);

  const loadReferenced = () => {
    api.get(`/me/${apiReferenced}?board=${board}`).then((response) => {
      setReferenced(response.data);
      setLoadingReferrer(false);
      setReferencedLoaded(true);
    });
  };

  const loadReferencedResume = () => {
    api.get(`/me/${apiResume}`).then((response) => {
      setReferencedResume(response.data);
      setReferencedLoadedResume(true);
    });
  };

  useEffect(() => {
    loadReferencedResume();
  }, []);

  useEffect(() => {
    setLoadingReferrer(true);
    loadReferenced();
  }, [board]);

  return (
    <>
      {!referencedLoaded || !referencedLoadedResume ? <LinearProgress /> : (
        <>
          <div style={{ marginBottom: 20 }}>
            <Typography gutterBottom variant="caption" style={{ color: 'rgb(59, 59, 59)', lineHeight: 0 }}>
              Saldo
            </Typography>
            <Box fontSize={48} fontWeight="fontWeightBold" style={{ color: 'rgb(59, 59, 59)', padding: 0 }}>
              {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(referencedResume.total_user_balance)} <span onClick={() => setOpenStatements('amount')} style={{ color: 'blue', fontSize: 12, cursor: 'pointer' }}>+ ver extrato</span>
            </Box>
            {referencedResume.total_user_balance_pending > 0 && (
              <div style={{ fontStyle: 'italic' }}>
                Aguardando liberação: {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(referencedResume.total_user_balance_pending)}
              </div>
            )}
          </div>

          {openLeadCreate && (
            <LeadClientCreate
              fromReferrer={true}
              open={openLeadCreate}
              onClose={() => setOpenLeadCreate(false)}
              onAfterSave={() => {
                loadReferenced();
                setOpenLeadCreate(false);
              }}
            />
          )}

          {openStatements && (
            <Statements
              open={openStatements}
              onClose={() => setOpenStatements(false)}
              apiStatements={apiStatements}
              amountField={amountField}
            />
          )}

          <Grid container spacing={4}>
            <Grid item xs={12} md={disablePoints ? 12 : 6}>
              <Card className={classes.root}>
                {!disableNew && (
                  <CardHeader
                    action={
                      <Button
                        name="new-lead"
                        startIcon={<PersonAddIcon />}
                        variant="outlined"
                        style={{ color: green[600] }}
                        onClick={() => setOpenLeadCreate(true)}
                      ><Hidden xsDown>Nova indicação</Hidden></Button>
                    }
                    title="Minhas indicações"
                    style={{ paddingBottom: 0 }}
                  />
                )}

                <div style={{ marginLeft: 10 }}>
                  <Button variant="text" className={board === 'doing' ? classes.actionRefersActive : classes.actionRefers} onClick={() => setBoard('doing')}>
                    Pendente
                  </Button>
                  <Button variant="text" className={board === 'gain' ? classes.actionRefersActive : classes.actionRefers} onClick={() => setBoard('gain')}>
                    Ganho
                  </Button>
                  <Button variant="text" className={board === 'lost' ? classes.actionRefersActive : classes.actionRefers} onClick={() => setBoard('lost')}>
                    Perdido
                  </Button>
                </div>

                {loadingReferrer ? <CircularProgress /> : (
                  <>
                    <List className={classes.refersList}>
                      {referenced.map((ref) => {
                        return (
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar aria-label="recipe" className={classes.referAvatar}>{ref.name.charAt(0)}</Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={
                              <>
                                {ref.name}
                                {board === 'doing' && ref.temperature && <>&nbsp;<TemperatureLabel temperature={ref.temperature} /></>}
                              </>
                            } secondary={
                              <>
                                {board !== 'gain' && board !== 'lost' && (
                                  <div>Etapa: {translateWorkflowStep('lead_client', ref.status)}</div>
                                )}

                                {board !== 'lost' && ref.amount_commission > 0 && (
                                  <div>{translateWorkflowStep('lead_client', new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(ref.amount_commission))}</div>
                                )}

                                {board === 'lost' && (
                                  <div>
                                    Motivo: {ref.lost_reason_name}
                                  </div>
                                )}
                                <div><small>Última atualização: {formatDateTime(ref.status_updated_at)}</small></div>
                              </>
                            } />
                          </ListItem>
                        );
                      })}

                      {referenced.length === 0 && (
                        <div style={{
                          padding: 25,
                          textAlign: 'center',
                          width: '100%',
                        }}>
                          Nenhuma indicação&nbsp;
                          {board === 'doing' && 'pendente'}
                          {board === 'gain' && 'ganha'}
                          {board === 'lost' && 'perdida'}
                        </div>
                      )}
                    </List>
                  </>
                )}
              </Card>
            </Grid>
            {!disablePoints && (
              <Grid item xs={12} md={6}>
                <Card className={classes.root}>
                  <CardHeader
                    title={
                      <>
                        {`Meus pontos: ${new Intl.NumberFormat('pt-BR').format(referencedResume.total_active_points)}`}
                      </>
                    }
                    subheader={
                      <>
                        {referencedResume.total_active_points_pending > 0 && (
                          <div>
                            Aguardando liberação: {new Intl.NumberFormat('pt-BR').format(referencedResume.total_active_points_pending)}
                          </div>
                        )}
                      </>
                    }
                    action={
                      <Button
                        startIcon={<ReceiptIcon />}
                        onClick={() => setOpenStatements('points')}
                      ><Hidden xsDown>Ver extrato</Hidden></Button>
                    }
                  />

                  <Prizes />
                </Card>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </>
  );
}