import { Button, Container, FormControlLabel, LinearProgress, Switch } from "@material-ui/core";
import React, { useState } from "react";
import api from "../../services/api";
import { setCurrentUser } from "../../services/auth";
import GeneralErrors from "../form/general-errors";
import ReferrerLicense from "./referrer";

export default function Assign({
  license,
  afterAssigned,
}) {
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState();
  
  const handleAssign = async() => {
    try {
      setLoading(true);
      const response = await api.post(`/me/license/${license}`);
      setCurrentUser(response.data);
      afterAssigned();
    } catch (e) {
      setErrors(e.response.data || 'Erro desconhecido');
    }

    setLoading(false);
  };

  return (
    <Container component="main" maxWidth="md">
      <div style={{background: 'gray', textAlign: 'center', padding: 10, color: 'white'}}>
        Leia o termo e assine no final desta página para utilizar o aplicativo.
      </div>

      {license === 'referrer' && <ReferrerLicense />}

      {errors && <GeneralErrors errors={errors} />}

      <p>
        <FormControlLabel required={true} control={
          <Switch
            onChange={(e) => setChecked(e.target.checked)}
            required={true}
          />
        } label="Li e aceito o termo acima." />
      </p>

      {loading && (<LinearProgress />)}
      <p>
        <Button
          onClick={handleAssign}
          disabled={!checked || loading}
          variant="contained"
          color="primary"
          name="to-assign"
          fullWidth
        >ASSINAR</Button>
      </p>
    </Container>
  );
}