import { Grid } from "@material-ui/core";
import React from "react";
import ReferrerUser from "./referrer-user";
import TopUsers from "../top_users";
import { getCurrentUser } from "../../services/auth";

export default function ReferrerOrganization() {
  const user = getCurrentUser();

  return (
    <>
      <ReferrerUser
        apiReferenced="referenced_organization"
        apiResume='referenced_organization_resume'
        apiStatements="/me/statements_organization"
        amountField="amount_organization"
        disablePoints
        disableNew
      />

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <TopUsers
            title="Top 5 - Com mais indicações"
            api={`/organization/${user.organization_id}/referrer/top_recommendations`}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TopUsers
            title="Top 5 - Com melhores vendas"
            api={`/organization/${user.organization_id}/referrer/top_sales`}
          />
        </Grid>
      </Grid>
    </>
  );
}