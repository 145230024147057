import React, { useEffect, useState } from 'react';
import { Avatar, Card, CardContent, CardHeader, LinearProgress, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles, MenuItem, Select, Typography } from "@material-ui/core";
import StarsIcon from '@material-ui/icons/Stars';
import apiService from '../services/api';
import GeneralErrors from './form/general-errors';

const useStyles = makeStyles({
  root: {
    minWidth: 375,
    minHeight: 350,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 24,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function TopUsers({
  title,
  api,
}) {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState();
  const [period, setPeriod] = React.useState('year');

  const handleChange = (event) => {
    setPeriod(event.target.value);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      setErrors(undefined);
      try {
        const response = await apiService.get(api, {
          params: {
            period: period
          }
        });
        setData(response.data);
      } catch (e) {
        setErrors(e?.response?.data || 'Erro desconhecido');
      }

      setLoading(false);
    })();
  }, [period]);


  return (
    <Card className={classes.root}>
      {loading ? <LinearProgress /> : (
        <CardContent>
          <CardHeader
            action={
              <div>
                <Select
                  value={period}
                  onChange={handleChange}
                >
                  <MenuItem value="year">Ano</MenuItem>
                  <MenuItem value="month">Mês atual</MenuItem>
                  <MenuItem value="month_before">Mês passado</MenuItem>
                  <MenuItem value="quarter">Trimestre</MenuItem>
                </Select>
              </div>
            }
            title={
              <Typography className={classes.title} color="textSecondary" gutterBottom><StarsIcon fontSize="large" style={{ color: 'orange' }} /> {title}</Typography>
            }
          />
          <Typography variant="body2" component="p">
            {data.length === 0 ? (
              <div style={{ textAlign: 'center', padding: 20 }}>Nenhum dado para apresentar</div>
            ) : data.map(row => {
              return (
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        {row.user_name && row.user_name.charAt(0)}
                        {!row.user_name && '?'}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <>
                          {row.user_name ? row.user_name : 'Sem usuário'}
                        </>
                      }
                      secondary={
                        <>
                          {row.total} {row.total > 1 ? 'indicações' : 'indicação'}
                        </>
                      }
                    />
                    <ListItemSecondaryAction>
                      <span style={{ fontSize: 20 }}>
                        {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.amount)}
                      </span>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              );
            })}
          </Typography>
        </CardContent>
      )}

      {errors && <GeneralErrors errors={errors} />}
    </Card>
  );
}