import React from "react";

export default function ReferrerLicense() {
  return (
    <>
      <h3>REGULAMENTO DO PROGRAMA DE AFILIADOS </h3>
      <p style={{ lineHeight: 1.5 }}>A empresa Criare da Vinci e o Programa de Afiliados regem os seguintes termos do “Regulamento do Programa de Afiliados”, que podem ser atualizados sem aviso prévio.</p>

      <p>
        <ol>
          <li>Adesão e Participação no Programa</li>
          <ol class="number-list">
            <li>1.1. A participação é voluntária e não possui nenhum custo ao Participante.</li>
            <li>1.2. A indicação do produto não possui qualquer vínculo de trabalho, cobrança de horário, ou quaisquer formalidades que ensejem vínculo de trabalho, sendo este um programa de benefícios pelo uso do aplicativo.</li>
            <li>1.3. Não há qualquer responsabilidade trabalhista envolvida no programa de afiliados.</li>
            <li>1.4. O participante pode a qualquer momento se desfiliar do programa, assim como a empresa Criare da Vinci pode encerrar o programa de afiliação ou o afiliado, sem aviso prévio.</li>
            <li>1.5. Ao entrar no Programa, o Participante se responsabiliza por fornecer seus dados cadastrais, assim como dados bancários, para que seja possível a Empresa Criare da Vinci efetivar os pagamentos de forma correta e dentro dos prazos estipulados neste regulamento.</li>
            <ol>
              <li>1.5.1. Poderá ser solicitada documentação diversa ao Participante para que comprove os dados cadastrais e bancários a qualquer tempo e em qualquer periodicidade.</li>
              <li>1.5.2. O Empresa Criare da Vinci poderá utilizar outra empresa como intermediadora dos pagamentos, sem a necessidade de prévia comunicação.</li>
            </ol>
          </ol>

          <li>Sistema de comissionamento e pontuação</li>
          <ol class="number-list">
            <li>2.1. O comissionamento e a pontuação é pessoal e intransferível e será nominal a cada afiliado.</li>
            <li>2.2. A pontuação expira conforme regras estabelecidas na campanha e não são acumulativas para outras campanhas.</li>
            <li>2.3. Para fins de pagamento neste programa, só será considerada a compra que for efetivada.</li>
            <ol>
              <li>2.3.1. Para fins de participação neste programa, não serão computadas auto indicações, ou seja, em caso que haja identidade de informações cadastrais entre o indicado e Afiliado.</li>
            </ol>
            <li>2.4. O cliente indicado deverá pagar o que foi comprado para que a venda seja considerada efetivada.</li>
            <li>2.5. O cliente deverá permanecer ativo e adimplente no momento da avaliação para fins de pagamento.</li>
            <li>2.6. A validação da indicação deverá ser realizada pela Empresa Criare da Vinci que se reserva o direito de anular a indicação em caso de não conformidade com qualquer das regras do presente regulamento.</li>
            <li>2.7. Indicações que já foram realizadas por outros indicadores serão anuladas.</li>
            <li>2.8. O Participante poderá divulgar a Empresa Criare da Vinci na internet e em perfil de redes sociais.</li>
          </ol>

          <li>Valores</li>
          <ol class="number-list">
            <li>3.1. O Participante receberá um único valor, conforme uma porcentagem fixa sobre a venda efetivamente e concretizada.</li>
            <li>3.2. O móvel deverá ser vendido exclusivamente pela plataforma Aplicativo Criare da Vinci conforme link indicado, nenhuma outra forma de pagamento será considerada.</li>
            <li>3.3. O valor referente à indicação ficará como “pendente” ou “em análise” até o término do curso. Nesse período, serão realizadas as validações do programa que podem necessitar mais tempo, o que será informado ao Participante.</li>
          </ol>

          <li>Pagamento</li>
          <ol class="number-list">
            <li>4.1. O pagamento das comissões será realizado, única e exclusivamente, através de depósito em conta bancária.</li>
            <li>4.2. Após a aprovação da empresa Criare da Vinci o valor referente a indicação será depositado e enviaremos o comprovante para o e-mail informado.</li>
            <li>4.3. Será cobrado R$ 15,00 (quinze reais) por depósito devolvido por inconsistências dos dados informados.</li>
            <li>4.4. Pode Criare da Vinci reter, suspender, cancelar e negar qualquer pagamento baseado nesse regulamento, nas leis nacionais e internacionais vigentes, normativas e circulares de autoridades ou órgãos públicos como o Banco Central, a Receita Federal, a Receita Estadual, assim como por determinação judicial.</li>
            <li>4.5. Para receber o valor, é necessário emissão de NF. Informaremos os dados para faturamento no e-mail com o extrato das vendas.</li>
          </ol>

          <li>Revogação, Suspensão e Cancelamento</li>
          <ol class="number-list">
            <li>5.1. O Participante poderá ter a sua participação suspensa, revogada ou cancelada, sem direito a receber os valores pendentes e qualquer outro existente na conta Afiliados, pela ocorrência de qualquer dos seguintes motivos:</li>
            <ol class="number-list">
              <li>a. Realizar publicidade em sites que contenham ou promovam atividades ilegais;</li>
              <li>b. Inserir anúncios em sites adultos;</li>
              <li>c. Atrelar o Empresa Criare da Vinci a sites ou conteúdos com fins políticos, religiosos ou de qualquer crença.</li>
              <li>d. Fraudar ou tentar fraudar o sistema do Programa;</li>
              <li>e. Por solicitação policial, de autoridade administrativa ou ainda por determinação judicial;</li>
              <li>f. Utilizar indevidamente a marca, o nome, o logo ou qualquer outra identificação visual que seja de propriedade da Criare da Vinci sem autorização;</li>
              <li>g. Utilizar de sistemas de propaganda pagos, como redes de pesquisa (Google, Bing, Yahoo e outras), AdNetworks, sistemas de Retargeting ou anúncios de Display;</li>
              <li>h. Utilizar meios de divulgação como envio de e-mails em massa não solicitados, ou qualquer outra forma de SPAM, incluindo, mas não limitando a, grupos de discussão, listas, fóruns.</li>
              <li>i. Fazer apropriação indevida da criação do conteúdo;</li>
            </ol>
            <li>5.2. O roll acima é meramente exemplificativo, podendo ainda haver a revogação, suspensão ou cancelamento sempre que houver uma infração a este regulamento que o cliente estiver vinculado.</li>
            <li>5.3. A suspensão também acarretará a perda dos valores pendentes de verificação ou pagamento referente a qualquer remuneração do Programa de ambos os programas.</li>
            <li>5.4. O Cancelamento da totalidade de serviços existentes com o Empresa Criare da Vinci acarretará, automaticamente, na perda de todos os valores e pontos acumulados por este programa incluindo, mas não se limitando, aos valores pendentes, aprovados e solicitados para saque.</li>
          </ol>

          <li>Regras Gerais</li>
          <ol class="number-list">
            <li>6.1. O crédito contido no programa de Afiliados poderá ser usado para quitação de alguma pendência financeira com o Empresa Criare da Vinci.</li>
            <li>6.2. O Empresa Criare da Vinci se reserva ao direito de realizar promoções próprias, criar e alterar os planos participantes do Programa sem aviso prévio ou notificação.</li>
            <li>6.3. Qualquer reclamação ou dúvida quanto ao programa deve ser contatado a equipe via e-mail contato@criaredavinci.com.br não sendo respondido através das redes sociais.</li>
          </ol>

          <li>Termos do Contrato</li>
          <ol class="number-list">
            <li>7.1. Os termos do Regulamento entrarão em vigor com o ingresso do Participante no programa Afiliados e permanecerão válidos até alteração e/ou encerramento do programa.</li>
            <li>7.2. Os termos estabelecidos no Regulamento podem ser alterados a qualquer tempo, por qualquer motivo, sem a necessidade de comunicação conforme necessidade e vontade do Empresa Criare da Vinci.</li>
            <li>7.3. Caso alguma das alterações e/ou atualizações realizadas, não seja da concordância do Participante este receberá todo o valor acumulado e deverá encerrar sua participação no programa. A continuidade no Programa irá constituir na aceitação de qualquer alteração.</li>
            <li>7.4. Em caso de ocorrência do disposto na cláusula supra, o Participante terá o prazo máximo de 5 (cinco) dias corridos para a realização do descadastramento. Após esse prazo, dar-se-ão por aceitas todas as alterações e/ou atualizações realizadas.</li>
          </ol>

          <li>Responsabilidade</li>
          <ol class="number-list">
            <li>8.1. A empresa Criare da Vinci não se responsabiliza pelas ações dos indicados que resultem em perda da indicação como: não utilização do aplicativo, criação de novo cadastro, inadimplência, entre outros.</li>
          </ol>
        </ol>
      </p>
    </>
  );
}